import * as React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const WritingPage = ({location}) => (
  <Layout location={location}>
    <SEO title="Sander Philipse's writing" />
    <p>
      I'll add some links to my writing here, eventually.
    </p>
  </Layout>
)

export default WritingPage
